import "./_body-view-height";
import "./header";
import "./_lazy-image.js";
import "./_slideshow-for-graphics-slider-configuration.js";
import "./_skew-slider-configuration.js";
import "./_skew-slider.js";
import "./_main-hero.js";
import "./_slideshow-for-graphics-slider.js";
import "./_logo-carousel-slider-configuration.js";
import "./_card-slider-configuration.js";
import "./_card-slider.js";
import "./_thing-to-love.js";
import "./_load-more-vacancies.js";
import "./_location-box-grid-slider-configuration.js";
import "./_location-box-grid-slider.js";
import "./_number-at-a-glance.js";
import "./_simple-image-slider-configuration.js";
import "./_simple-image-slider.js";
import "./_steps.js";

import { intersectionObserve } from "./_intersection-observer";
import { closeDropdown } from "./header";

let lastScrollTop = 0;
let skipHeader = document.querySelector("a.visually-hidden.skip-to-main");
const header = document.querySelector("header");
const body = document.querySelector("body");

if (skipHeader != undefined) {
	skipHeader.addEventListener("focusin", function (e) {
		this.classList.add("focused");
	});
	skipHeader.addEventListener("focusout", function (e) {
		this.classList.remove("focused");
	});
}

window.onscroll = function () {
	const mobileMenu = document.querySelector(".mobile-menu");
	const mobileMenuButton = document.querySelector(".mobile-menu-button");

	let st = window.pageYOffset || document.documentElement.scrollTop;
	if (st > lastScrollTop && st > 300) {
		header.classList.add("scroll-down");
		header.classList.remove("scroll-up");
		if (mobileMenu.classList.contains("active")) {
			mobileMenu.classList.remove("active");
			mobileMenuButton?.classList.remove("active");
			body.style.overflow = "auto";
		}
		closeDropdown();
	} else if (st < lastScrollTop) {
		header.classList.add("scroll-up");
		header.classList.remove("scroll-down");
	}
	lastScrollTop = st <= 0 ? 0 : st;
};

const heroTop = document.querySelector(
	"section.main-hero-top, section.keywords"
);

intersectionObserve(heroTop, 0, 0, (heroTop, intersectionRatio) => {
	(function (el) {
		if (intersectionRatio > 0) {
			header.classList.add("light-mode");
		} else {
			header.classList.remove("light-mode");
		}
	})(heroTop);
});

window.addEventListener(
	"CookiebotOnDecline",
	function (e) {
		if (!Cookiebot.consent.statistics) {
			if (window.clarity) {
				window.clarity("stop");
			}
		} else {
			if (window.clarity) {
				window.clarity("start");
			}
		}
	},
	false
);

window.addEventListener(
	"CookiebotOnAccept",
	function (e) {
		if (!Cookiebot.consent.statistics) {
			if (window.clarity) {
				window.clarity("stop");
			}
		} else {
			if (window.clarity) {
				window.clarity("start");
			}
		}
	},
	false
);

import "./_box-grid.js";
import "./_lazy-video.js";
import "./_subscription-plans.js";
